import React from "react";
import type {IGalleryImage} from "../../interfaces/IGalleryImage";

const renderItem = (item: IGalleryImage | any) => {
    if (item.isVideo) {
        return (
            <div className="ratio ratio-16x9 embed-responsive-item w-100">
                <iframe src={item.original} title={item.description} allowFullScreen allow="autoplay"></iframe>
            </div>
        );
    }
    return (<img className="image-gallery-image" src={item.original} alt={item.description} />);
};

export default renderItem;
