import {Container, Row, Col, Image, Button} from "react-bootstrap";
import i18n from "../../i18n";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {NavLink} from "react-router-dom";
import {getUrlLangPrefix} from "../../utils/Local";

const ShopScreen: React.FC = () => {

    const {t} = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>
        <Container fluid className="screenContainer">

            <Container className="flexContainer pakvertContainer">
                <Row>
                    <Col xs={12} lg={6} className="mt-5 mt-lg-0 installationContent order-2 order-lg-1">
                        <div className="flexContainer">
                            <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce>
                                <Image src="/images/home/Pakvert_Shop.png" rounded className="mw-100"
                                       alt={`${t('Shop PakVert')}`}/>
                            </AnimationOnScroll>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className="order-1 order-lg-2">
                        <div className="flexContainer" style={{gap: '8px'}}>
                            <h2>{t('Shop PakVert')}</h2>
                            <h3>{t('Your All-in-One Building Solution')}</h3>
                            <p>{t('PakVert panels are a versatile, all-in-one solution for walls, floors, and ceilings. Made from recycled material, they deliver cost-effective, energy-efficient, and eco-friendly construction without compromising on quality or design. Customize effortlessly with the flexibility to saw and screw into PakVert Panels just like wood. Perfect for building homes, extensions, or any other project.')}</p>
                            <NavLink to={`${langPrefix}/${t('_route.shop')}/pakvert`}>
                                <Button className="btn-primary">{t('Buy Now')}</Button>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </>)
}

export default ShopScreen;