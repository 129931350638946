import {Container, Row, Col, Image} from "react-bootstrap";
import i18n from "../../i18n";

const TechnologyScreen: React.FC = () => {

    const {t, language} = i18n;

    return (<>
        <Container fluid className="screenContainer" style={{backgroundColor: 'var(--bg-light-color)'}}>
            <Container className="flexContainer">
                <Row>
                    <Col xs={12} lg={5} className="order-2 order-lg-1 mt-4 mt-lg-0">
                        <div className="flexContainer">
                            <Image src={`/images/technology/hero_${language}.png`} rounded style={{maxWidth: '90%'}}
                                   alt={`${t('_technology_screen.0.title')}`}/>
                        </div>
                    </Col>

                    <Col xs={12} lg={7} className="order-1 order-lg-2">
                        <div className="flexContainer installationContent technologyContent">
                            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center w-100">
                                <h2 style={{color: 'var(--info-color)'}}>{t('_technology_screen.0.title')}</h2>
                                <Image src="/images/PakVille_Patent_pending_badge.png" alt="PakVille-Patent" style={{maxWidth: '160px'}}></Image>
                            </div>
                            <p>{t('_technology_screen.0.description')}</p>
                        </div>
                    </Col>
                </Row>

            </Container>

        </Container>
    </>)
}

export default TechnologyScreen;