import { Container, Row, Col } from "react-bootstrap";
import { IStandardFeatures } from "../interfaces/IStandardFeatures";
import i18n from "../i18n";

interface StandardFeaturesComponentProps {
    modelName: string,
    standardFeatures: IStandardFeatures

}

const dimensions = (type: string) => {
    switch (type) {
        case 'standard_floor_panel':
            return `(46" x 140" x 4")`

        case 'standard_floor_panel_lg':
            return `(46" x 142" x 4")`

        case 'standard_floor_panel_md':
            return `(46" x 96" x 4")`

        case 'standard_wall_panel':
            return `(46" x 84" x 4")`
        
        case 'standard_wall_panel_md':
            return `(46" x 96" x 4")`    

        case 'standard_wall_panel_var':
            return `(46" x var. x 4")`

        case 'oversize_wall_panel':
            return `(46" x 103" x 4")`

        case 'roof_panel':
            return `(46" x 81" x 4")`

        case 'roof_panel_lg':
            return `(46" x 190" x 4")`

        case 'roof_panel_md':
            return `(46" x 142" x 4")`
        
        case 'roof_panel_md_2':
            return `(46" x 96" x 4")`

        case 'roof_panel_sm':
            return `(46" x 46" x 4")`

        case 'triangular_panel':
            return `(40" x 70" x 4")`
            
        case 'patio_door_sm':
            return `(64" x 79" x 5 3/4")`

        case 'patio_door':
            return `(82" x 79" x 5 3/4")`

        case 'oversize_patio_door':
            return `(108" x 79" x 5 3/4")`

        case 'oversize_patio_door_lg':
            return `(139" x 79" x 5 3/4") `

        case 'triangular_fixed_window':
            return `(108" x 31")`

        case 'side_window_lg':
            return `(84" x 18")`

        case 'side_window_md':
            return `(60" x 18")`

        case 'side_window_sm':
            return `(24" x 24")`
        
        case 'side_window_md_2':
            return `(72" x 24")`

        case 'side_window_md_3':
            return `(60" x 24")`

        case 'oversize_front_door':
            return `(82" x 68" x 5 3/4")`
        default:
            return ''
    }
}

const StandardFeaturesComponent: React.FC<StandardFeaturesComponentProps> = ({ modelName, standardFeatures }) => {

    const { t } = i18n;

    return (<>
        <Container fluid className="mt-4 mb-4 standard-features-container">

            <Container>
                <Row>
                    <Col xs={12}>
                        <h3>{modelName}</h3>
                    </Col>
                    <Col xs={12} style={{ fontSize: '1rem' }}>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="ms-0">
                                {t('total_living_space')}: {standardFeatures.total_living_space} {t('sq_ft')}
                            </li>
                            <li className="ms-0">
                                {t('footprint')}: {standardFeatures.footprint} {t('sq_ft')}
                            </li>
                            <li className="ms-0">
                                {t('made_of_x_modules_of_y_wide', { x: standardFeatures.num_of_modules, y: 46 })}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <ul>{t('inclusions')}
                        {standardFeatures.inclusions.map((value, index: number) => {
                            return (
                                <li key={index} >
                                    {value.qty} {t(value.item)} {dimensions(value.item)}
                                </li>
                            )
                        })}
                    </ul>
                </Row>

                <Row>
                    <ul>{t('electricals')}
                        {standardFeatures.electricals.map((value, index: number) => {
                            return (
                                <li key={index} >
                                    {value.qty} {t(value.item)}
                                </li>
                            )
                        })}
                    </ul>
                </Row>

                <Row>
                    <ul>{t('design')}

                        <li>
                            <ul>{t('customize_your_pakVert_interior')}:
                                {standardFeatures.design.interior.map((value, index: number) => {
                                    return (
                                        <li key={index} >
                                            {t(value)}
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                        <li>
                            <ul>{t('customize_your_pakVert_exterior')}:
                                {standardFeatures.design.exterior.map((value, index: number) => {
                                    return (
                                        <li key={index} >
                                            {t(value)}
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    </ul>
                </Row>

            </Container>



        </Container>
    </>)
}

export default StandardFeaturesComponent;