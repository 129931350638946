import i18n from "../../i18n";
import ExhibitionComponent from "../Exhibition.component";
import {IExhibition} from "../../interfaces/IExhibition";

const ExhibitionScreen: React.FC = () => {
    const { t } = i18n;
    const list = [
        {
            address: t('_exhibitions.0.address'),
            date: t('_exhibitions.0.date'),
            btnLabel: t('_exhibitions.0.btn_label'),
            link: t('_exhibitions.0.link'),
            googleMap: 'https://maps.app.goo.gl/SXPZik1xbFovQkGM9',
            startDate: '2025-04-11',
            endDate: '2025-04-13',
            title: t('_exhibitions.0.title'),
            description: t('_exhibitions.0.description'),
            image: '/images/exhibitions/2025_04_ottawa.png',
            name: t('_exhibitions.0.name'),
            booth_no: '1319',
            promoLink: t('_exhibitions.0.promo_link')
        }
    ] as IExhibition[];

    return (<>
            {list.map((item: IExhibition, index) => (
                <ExhibitionComponent info={item} key={index}/>
            ))}
    </>)
}

export default ExhibitionScreen;