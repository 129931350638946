import i18n from 'i18next';

export function getLocale() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const locale = params.get('lang');
    const validLocales = i18n.languages;
    if (locale && validLocales && validLocales.includes(locale)) {
        return locale;
    }
    return 'en';
}

export const getCurrentLang = () => {
    const firstPath = window.location.pathname.split('/')[1];
    return firstPath === 'fr' ? 'fr' : 'en'
}

export const getUrlLangPrefix = () => {
    return getCurrentLang() === 'fr' ? '/fr' : ''
}

export const getSalesPhoneNumber = () => {
    let phoneNumber = process.env.REACT_APP_SALES_PHONE_NUMBER || '';
    if (getCurrentLang() === 'fr' && process.env.REACT_APP_SALES_PHONE_NUMBER_FR) {
        phoneNumber = process.env.REACT_APP_SALES_PHONE_NUMBER_FR;
    }
    return phoneNumber;
}

export const getSalesCalendarLink = () => {
    let link = process.env.REACT_APP_CALENDAR_LINK || '';
    if (getCurrentLang() === 'fr' && process.env.REACT_APP_CALENDAR_LINK_FR) {
        link = process.env.REACT_APP_CALENDAR_LINK_FR;
    }
    return link;
}

export const getPaymentLink = () => {
    let link = process.env.REACT_APP_PAY_LINK || '';
    if (getCurrentLang() === 'fr' && process.env.REACT_APP_PAY_LINK_FR) {
        link = process.env.REACT_APP_PAY_LINK_FR;
    }
    return link;
}