import React, { useEffect } from "react";
import Loader from './Loader.component'
import {getPaymentLink, getSalesCalendarLink} from "../utils/Local";

type RedirectComponentProps = {
    type: string
}

const RedirectComponent: React.FC<RedirectComponentProps> = ({
    type
}) => {
  useEffect(() => {

    // Define redirection URLs based on type
    const typeToUrlMap: Record<string, string> = {
      sac: getSalesCalendarLink(),
      psd: getPaymentLink()
    };

    if (type && typeToUrlMap[type]) {
      window.location.href = typeToUrlMap[type];
    } else {
      window.location.href = '';
    }
  }, [type]);

  return (
    <>
        <Loader /> Redirecting...
    </>
  );
};

export default RedirectComponent;
