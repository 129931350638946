import {Container, Row, Col} from "react-bootstrap";
import i18n from "../../i18n";
import {getCurrentLang} from "../../utils/Local";
import {ReactComponent as FaAffordable} from '../../assets/icons/faAffordable.svg';
import {ReactComponent as FaSustainableHome} from '../../assets/icons/faSustainableHome.svg';
import {ReactComponent as FaSpeedyConstruction} from '../../assets/icons/faSpeedyConstruction.svg';

const IntroScreen: React.FC = () => {

    const { t } = i18n;
    const reasons = [
        {
            icon: <FaSustainableHome/>,
            title: t('Sustainable'),
            percentage: 100,
            // subtitle: t('more energy-efficient'),
            note: t('recycled plastic bottles are transformed into durable panels, fully recyclable at the end of their lifetime, leaving minimal impact on the environment.'),
        },
        {
            icon: <FaSpeedyConstruction/>,
            title: t('Speedy Construction'),
            percentage: 90,
            // subtitle: t('faster'),
            note: t('faster construction with modular design, allowing your home to be built in just weeks.'),
        },
        {
            icon: <FaAffordable/>,
            title: t('Affordable'),
            percentage: 35,
            // subtitle: t('less expensive,'),
            note: t('less expensive than traditional construction, making dream homes accessible to more people.'),
        },
    ];

    return (<>
        <Container fluid className="screenContainer introContainer" style={{backgroundColor: '#FFFFFF'}}>
            <Container className="flexContainer">
                <Row className="w-100">
                    <Col xs={12}>
                        <h1 className="text-start">
                            {t('Why choose PakVille')}?
                        </h1>
                        <p className="mt-4 text-start">
                            {t('PakVille offers a smarter way to build homes—combining affordability, energy efficiency, and rapid construction with sustainable innovation.')}
                        </p>
                    </Col>
                </Row>

                <Row>
                    {reasons.map((reason, index) => {
                        return (
                            <Col xs={12} lg={4} key={index}>
                                <div className="pakvilleReasonContainer">
                                    {reason.icon}
                                    <h2>{reason.title}</h2>
                                    <p>
                                        <span>{reason.percentage}{getCurrentLang() === 'fr' ? ' ' : ''}%</span>&nbsp;
                                        {/*<span>{reason.subtitle}</span>&nbsp;*/}
                                        <span>{reason.note}</span>
                                    </p>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    </>)
}

export default IntroScreen;