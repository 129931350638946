import CarouselComponent from "../components/Carousel.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import IntroScreen from "../components/screens/intro.components";
import PakVertScreen from "../components/screens/PakVert.component";
import InstallationScreen from "../components/screens/Installation.component";
import BenefitsScreen from "../components/screens/Benefits.component";
import ExhibitionScreen from "../components/screens/Exhibition.component";
import BlogScreen from "../components/screens/LatestBlogs.component";
import ShopScreen from "../components/screens/Shop.component";
import SignatureModelsScreen from "../components/screens/SignatureModels.component";

export default function Home() {

    return (<>

        <DocumentComponent title={''} />

        <CarouselComponent />

        <IntroScreen />

        <InstallationScreen />

        <SignatureModelsScreen />

        <PakVertScreen />

        <ShopScreen />

        <BenefitsScreen />

        <ScheduleComponent />

        <ExhibitionScreen />

        <BlogScreen />

    </>)
}