import {useState} from "react";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import DocumentComponent from "../components/Document.component";
import HeroComponent from "../components/Hero.component";
import i18n from "../i18n"
import {formatter} from "../utils/Toolbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBottleWater, faDollar, faRuler, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import modelsData from "../data/models.json"
import PremiumUpgradesComponent from "../components/PremiumUpgrades.component";
import ContainerDivider from "../components/ContainerDivider.component";
import ScheduleComponent from "../components/Schedule.component";
import {getUrlLangPrefix} from "../utils/Local";

// Variant
export interface IModel {
    name: string,
    url_name: string,
    size: string,
    price: number,
    bottles: number,
    description: string,
    slug: string,
    hero: string,
    img: string,
    footprint: number,
    total_living_space: number,
    sizes?: Array<{ label: string, value: number }>,
    size_with_note?: { label: string, value: number, note: string },
}

export default function Models() {

    const {t} = i18n;
    const langPrefix = getUrlLangPrefix()
    const [showHiddenModels, setShowHiddenModels] = useState(false);

    /** Fetch first variant of each product */
    const models = [
        modelsData[6]['variants'][0] as IModel,
        modelsData[0]['variants'][0] as IModel,
        modelsData[1]['variants'][0] as IModel,
        modelsData[4]['variants'][0] as IModel,
        modelsData[3]['variants'][0] as IModel,
    ]

    const hiddenModels = [
        modelsData[5]['variants'][0] as IModel,
        modelsData[2]['variants'][0] as IModel,
    ]

    return (<>

        <DocumentComponent title={t('signature_models')}/>

        <HeroComponent title={t('signature_models')}/>

        <Container style={{marginBottom: '5rem'}}>
            <Row>
                {renderModels(models, langPrefix, t)}
                {showHiddenModels &&
                    renderModels(hiddenModels, langPrefix, t)
                }
            </Row>

            {!showHiddenModels &&
                <Row>
                    <Col xs={12} className='text-center mt-4'>
                        <Button size="sm" variant="outline-success ps-4 pe-4"
                                onClick={() => setShowHiddenModels(true)}
                                className="btn-outline">
                            {t('load_more')}
                        </Button>
                    </Col>
                </Row>
            }

        </Container>

        <ContainerDivider title={t('premium_upgrades')}/>

        <PremiumUpgradesComponent/>

        <ScheduleComponent/>

    </>)
}

const renderModels = (models: IModel[], langPrefix: string, t: any) => {
    return models.map((model: IModel, index: number) => {
        const col = model.name.toLowerCase().startsWith('pakmiami') ? 12 : 6;
        return (<Col key={index} lg={col} className="mb-5 mb-lg-4">
                <Card className="p-0 h-100">
                    <NavLink to={`${langPrefix}/${t('_route.models')}/${model.url_name}`}
                             className='text-center text-lg-end'>
                        <Card.Img variant="top" src={model.hero}/>
                    </NavLink>

                    <Card.Body className="modelSummaryContainer">
                        <Card.Title><h2 className="modelHeader text-center">{t(model.name.split(' ')[0])}</h2>
                        </Card.Title>

                        <Card.Text className="modelSubHeader mb-0">
                            <FontAwesomeIcon icon={faBottleWater} color="var(--info-color)"/>
                            <span>{t('made_from_x_recycled_water_bottles', {x: model.bottles.toLocaleString()})}</span>
                        </Card.Text>
                        <Card.Text className="modelSubHeader mb-0">
                            <FontAwesomeIcon icon={faDollar} color="var(--info-color)"/>
                            <span>{t('starting_from_price', {price: formatter.format(model.price)})}</span>
                        </Card.Text>
                        <Card.Text className="modelSubHeader mb-0">
                            {model.sizes ?
                                <span className="d-flex flex-column">
                                            {model.sizes.map((modelSize, modelSizeIndex) => {
                                                return (
                                                    <span className="d-flex" key={modelSizeIndex}>
                                                    <FontAwesomeIcon icon={faRuler} color="var(--info-color)"/>
                                                        {t(modelSize.label)}: {modelSize.value} {t('sq_ft')}
                                                    </span>
                                                )
                                            })}
                                        </span>
                                :
                                model.size_with_note ?
                                    <span className="d-flex flex-column flex-lg-row gap-2">
                                        <span className="d-flex">
                                            <FontAwesomeIcon icon={faRuler} color="var(--info-color)"/>
                                            {t(model.size_with_note.label)}: {model.size_with_note.value} {t('sq_ft')}
                                        </span>
                                        <span className="ms-3 ps-3 ms-lg-0 ps-lg-0">({t(model.size_with_note.note)})</span>
                                    </span>
                                    :
                                    <>
                                        <FontAwesomeIcon icon={faRuler} color="var(--info-color)"/>
                                        <span>{t('size')}: {model.footprint} {t('sq_ft')}</span>
                                    </>
                            }
                        </Card.Text>

                        <Card.Text className="mt-3">
                            {t(model.description)}
                        </Card.Text>
                    </Card.Body>

                    <Card.Footer style={{background: 'unset', border: 'unset'}} className=" mb-2">
                        <NavLink to={`${langPrefix}/${t('_route.models')}/${model.url_name}`}
                                 className='text-center text-lg-end'>
                            <span style={{
                                fontWeight: 600,
                                fontSize: '1.1rem',
                                color: 'var(--info-color)'
                            }}>{t('learn_more')} <FontAwesomeIcon icon={faChevronRight}/></span>
                        </NavLink>
                    </Card.Footer>
                </Card>

            </Col>
        )
    });
}