import { Carousel, Container, Image } from "react-bootstrap";
import i18n from "../i18n";

interface ICarouselItem {
    img: string,
    alt: string
}

const CarouselComponent: React.FC = () => {

    const { t } = i18n;
    const items: ICarouselItem[] = [
        {
            img: 'PakAlps_exterior04.jpg',
            alt: 'PakMontreal_exterior04'
        },
        {
            img: 'Model8_Interior01.jpg',
            alt: 'PakTokyo_interior01'
        },
        {
            img: 'PakMiami-exterior01.jpg',
            alt: 'PakMiami-exterior01'
        },
        {
            img: 'Model8_Interior03.jpg',
            alt: 'PakTokyo_interior03'
        },
        {
            img: 'Model8_Interior02.jpg',
            alt: 'PakTokyo_interior02'
        },
        {
            img: 'PakAlps_exterior01.png',
            alt: 'PakAlps_exterior01'
        },
        {
            img: 'PakAlps_interior01.png',
            alt: 'PakAlps_interior01'
        },
        {
            img: 'PakAlps_interior02.png',
            alt: 'PakAlps_interior02'
        },
        {
            img: 'PakShiraz_LuxeLoft_exterior02.jpg',
            alt: 'PakShiraz_LuxeLoft_exterior02'
        },
        {
            img: '10_Model4_interior01.png',
            alt: 'PakShiraz_interior01'
        },
        {
            img: '11_Model4_interior04.png',
            alt: 'PakShiraz_interior02'
        },
        {
            img: '12_Model3_interior01.jpg',
            alt: 'PakShiraz_interior03'
        }
    ];

    return (<>

        <Container fluid style={{ backgroundColor: 'var(--bg-light-color)', padding: 0 }}>
            <Carousel>
                {
                    items.map((item: ICarouselItem, index: number) => (
                        <Carousel.Item className="text-center" key={index}  >
                            <Image className="d-xl-none mw-100" src={`/images/carousel/${item.img}`}  title={item.alt} alt={item.alt} />
                            <div className="d-none d-xl-block" style={{ backgroundImage: `url(/images/carousel/${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center', height: "calc(100vh - 155px )" }}></div>
                            {index === 0 &&
                                <Carousel.Caption>
                                    <span>PakVille</span>
                                    <span>{t('Your Dream Home')}</span>
                                </Carousel.Caption>
                            }
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </Container>

    </>)
}

export default CarouselComponent;